import { TransType } from 'hooks/useTranslations';

import { REGION_INFO } from './regionBasedConstants';

export function regionBasedSchema(countryCode: string, t: TransType) {
  const country = REGION_INFO[countryCode];

  if (!country) {
    return {
      '@context': 'https://schema.org',
      '@type': 'OnlineBusiness',
      name: 'PUMA',
      url: `https://${countryCode}.puma.com/`,
      brand: {
        '@type': 'Brand',
        name: 'PUMA',
      },
      logo: `https://${countryCode}.puma.com/assets/logo-puma-black.png`,
      slogan: 'Forever Faster',
      legalName: t('legalName'),
    };
  }

  return {
    '@context': 'http://schema.org',
    '@type': 'OnlineBusiness',
    parentOrganization: {
      name: 'PUMA',
      legalName: 'PUMA',
      url: 'https://www.puma.com/',
      numberofEmployees: '13000',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'PUMA WAY 1',
        addressLocality: 'Herzogenaurach',
        postalCode: '91074',
        addressCountry: 'Germany',
      },
      founder: {
        '@type': 'Person',
        name: 'Rudolf Dassler',
        sameAs: ['https://en.wikipedia.org/wiki/Rudolf_Dassler'],
      },
      foundingLocation: {
        '@type': 'Place',
        name: 'Herzogenaurach, Germany',
        sameAs: ['https://en.wikipedia.org/wiki/Herzogenaurach'],
      },
      ethicsPolicy:
        'https://about.puma.com/en/sustainability/codes-and-handbooks',
      foundingDate: '1948',
    },
    name: `PUMA ${country.name}`,
    legalName: `${country.legalName}`,
    url: `${country.url}`,
    logo: `${country.logo}`,
    brand: {
      '@type': 'Organization',
      Name: 'PUMA',
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${country.streetAddress}`,
      addressLocality: `${country.addressLocality}`,
      postalCode: `${country.postalCode}`,
      addressCountry: `${country.addressCountry}`,
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: `${country.telephone}`,
        email: `${country.email}`,
        areaServed: `${country.areaServed}`,
        contactType: 'customer service',
      },
    ],
    potentialAction: {
      '@type': 'SearchAction',
      target: `${country.url}search?q=shoes{search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
    sameAs: [
      'https://www.instagram.com/puma/',
      'https://www.instagram.com/pumasportstyle/',
      'https://x.com/puma',
      'https://www.linkedin.com/company/puma',
      'https://plus.google.com/+puma',
      'https://www.facebook.com/PUMA',
      'https://www.youtube.com/puma/',
      'https://en.wikipedia.org/wiki/Puma_(brand)',
      'https://www.crunchbase.com/organization/puma',
      'https://pumagroup.tumblr.com/',
      'https://about.puma.com/en',
      'https://www.reddit.com/r/PUMA/',
      'https://www.puma-catchup.com/',
      'https://www.youtube.com/user/puma',
    ],
  };
}
